@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html{
  font-size: 100%;
}
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
  body{
  font-family: 'Poppins', sans-serif; 
  box-sizing: border-box;
}

/* common-width */
.width-68{
  width: 68%;
}
.width-300{
  width: 300px;
}

/* common-border */
.b-bottom{
  border-bottom: 1px solid rgb(214, 214, 214);
}
/* text-common-font */
.text-18{
  font-size: 18px;
  font-weight: 300;
  line-height: 1.8;
  padding: 8px 0px;
}
.text-15{
  font-size: 15px;
  font-weight: 290;
  line-height: 1.8;
  padding: 7px 0px;
}
.blue-blank-sec{
  height: 120px;
  background-image: linear-gradient(to bottom, #3f4c6b, #404d6c, #414e6d, #424f6e, #43506f, #455270, #475472, #495673, #4c5975, #505b77, #535e7a, #56617c);
  border-top: 4px solid #3B4356;
  border-bottom: 4px solid #3B4356;
}
.path-sec{
  background-image: linear-gradient(to bottom, #edf0f3, #f2f4f6, #f7f7f9, #fbfbfc, #ffffff);
height: 45px;
border-bottom: 1px solid rgb(214, 214, 214);
}
.path-sec .home-icon{
  font-size: 14px;
  color: #999;
}
.path-11{
  font-size: 11px;
  font-weight: 500;
  color: #999 !important;
}
.text-section{
  background-color: #FEFEFE;
}
.nobull {
  list-style-type: none;
  padding:  0.5em;
 }
/* about-page-text */
.about-32{
  font-size: 32px;
  letter-spacing: 1px;
  color: #666666;
  font-weight: 200;
}
.about-14-italic{
  font-size: 14px;
  font-style: italic;
  color: #555555;
  font-weight: 500;
}
.about-15-italic{
  font-size: 15px;
  font-style: italic;
  color: #555555;
  font-weight: 250;
}
.about-12{
  font-size: 12px;
  color: #777;
  font-weight: 400;
  line-height: 1.8;
}
.about-14{
  font-size: 14px;
  color: #777;
  font-weight: 400;
  line-height: 1.8;
}
/* services */
.service-12{
  font-size: 12px;
  color: #777;
  font-weight: 500;
  line-height: 1.8;
}
/* contact */
.send-btn{
  background-color: #3F4C6B;
  color: #fff;
  font-size: 11px;
  border: none;
  outline: none;
  height: 30px;
  width: 50px;
  border-radius: 2px;
}
.read-btn{
  background-color:#F8F8F8;
  color: #777;
  font-size: 11px;
  border: 1px solid #777;
  outline: none;
  height: 30px;
  width: 100px;
  border-radius: 2px;
}
.input{
  border: 1px solid #E5E5E5;
  width: 300px;
  outline: none;
  height: 30px;
  font-size: 12px;
  padding: 0px 10px;
}
.textarea{
  border: 1px solid #E5E5E5;
  outline: none;
  font-size: 12px;
  padding: 5px 10px;
}
.italic-11{
  font-size: 11px;
  font-weight: 400;
  font-style: italic;
  color: #777;
}
.showcase-26{
  font-size: 26px;
  letter-spacing: 1px;
  color: #666666;
  font-weight: 200;
}
.slider-wrapper{
  padding: 30px 200px;
  background-image: linear-gradient(to bottom, #3f4c6b, #404d6c, #414e6d, #424f6e, #43506f, #455270, #475472, #495673, #4c5975, #505b77, #535e7a, #56617c);
  border-top: 4px solid #3B4356;
  border-bottom: 4px solid #3B4356;
}
.spaced-image {
    padding-left: 4em; 
    padding-right: 4em; 
}
@media screen and (max-width:992px){
  .slider-wrapper{
    padding: 20px;
  }
}
@media screen and (max-width:575px){
  .width-68{
    width: 80%;
  }
}